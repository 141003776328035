import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModTwoImgCols from "../../../components/Chronology/Modules/ModTwoImgCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModReport from "../../../components/Chronology/Modules/ModReport";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModVideo from "../../../components/Chronology/Modules/ModVideo";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";
import ModDataEs from "../../../components/Chronology/Modules/ModDataEs";

export const frontmatter = {
  title: "Día 111",
  week: "Semana 16",
  day: "02",
  month: "Jul",
  monthNumber: "07",
  date: "2020-07-02",
  path: "/cronologia/semana-16#dia-02-jul/",
};

const Day111 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 2.106 casos mediante pruebas PCR. En ese mismo
          periodo, 136 personas han requerido hospitalización (6,5% de los
          diagnósticos), de los que 11 han sido ingresos en la UCI, y se han
          producido 24 fallecimientos.
        </ModText>
        <ModText>
          Ha llegado hoy a Madrid el primer tren de suministros sanitarios, con
          28 millones de mascarillas y más de 400.000 buzos desechables. El tren
          partió desde China el 3 de junio, con 35 vagones, equivalentes a
          aproximadamente cuatro aviones de gran carga.
        </ModText>
        <ModImage
          src="/images/svg/08_abr_trenes_medicalizados.svg"
          alt="tren medicalizado"
        />

        <ModText>
          Con esta nueva modalidad de transporte de material, el Ministerio de
          Sanidad optimiza los costes derivados de estas importaciones.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day111;
